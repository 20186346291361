import activeAjax from './activeAjax.js'
$(document).on("ajaxStart", activeAjax.start)
$(document).on("ajaxStop", activeAjax.stop)

$(function () {
  const channel = new BroadcastChannel('cdd_vault_channel')
  setTimeout(() => {
    // let other pages know that we are on the login page and so should sign out
    channel.postMessage('redirectLogout')
  })

  channel.onmessage = (event) => {
    if (event.data == 'isLoggedIn') {
      // we are logged back in on another tab. If the search params contain 'redirect_url', then go there now
      const urlParams = new URLSearchParams(window.location.search)
      const redirect_url = urlParams.get('redirect_url')
      if (redirect_url && redirect_url.startsWith(window.location.origin)) {
        window.location.href = decodeURIComponent(redirect_url)
      } else {
        window.location.reload()
      }
    }
  }
})
